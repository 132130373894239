import * as React from "react";

import "../styles/base.css";
import { AdminLogin } from "../components/utils/AdminLogin";

const Admin = () => {
  return <AdminLogin />;
};

export default Admin;
