import React, { useEffect } from "react";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { navigate } from "gatsby";
import { AdminLoginWithPassword } from "./AdminLoginWithPassword";

export const AdminLogin = () => {
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
          return;
        }
        if (user) navigate('/blog-edit');
      }, [user, loading]);

    return (
        <>
            <div className="flex justify-center">
                <AdminLoginWithPassword />
            </div>
        </>
    )
}