import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

export const singInWithPassword = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        const user = res.user;
        return user;
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
}